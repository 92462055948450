import React from 'react';
import Slide from 'react-reveal/Slide';

import SEO from '../components/seo';

import { Menu, Item } from '../styles/styles.js';

const IndexPage = () => {
  return (
    <>
      <SEO
        title="Pizzaria Império Cardápio"
        keywords={[
          `pizzas`,
          `pizzas doces`,
          `sucos`,
          `bebidas`,
          `disk entregas`,
        ]}
      />

      <Menu className="menu">
        <Slide left cascade duration={300}>
          <h1 className="menu-title">Pizzas</h1>

          <h2 className="menu-info">
            Todas as pizzas salgadas vão com borda de requeijão grátis
          </h2>
        </Slide>

        <div className="menu-sizes">
          <p>4 Pdç</p>
          <p>8 Pdç</p>
          <p>12 Pdç</p>
        </div>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">01 - Americana</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">41,00</h4>
              <h4 className="item-price">56,00</h4>
              <h4 className="item-price">70,00</h4>
            </div>
          </div>

          <p className="item-desc">
            Molho, presunto, palmito, ervilha, ovos, milho, bacon, mussarela,
            azeitonas e orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">02 - Atum</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">39,00</h4>
              <h4 className="item-price">54,00</h4>
              <h4 className="item-price">68,00</h4>
            </div>
          </div>

          <p className="item-desc">
            Molho, mussarela, atum, tomate, cebola, azeitonas e orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">03 - Bacon</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">35,00</h4>
              <h4 className="item-price">50,00</h4>
              <h4 className="item-price">64,00</h4>
            </div>
          </div>

          <p className="item-desc">
            Molho, mussarela, bacon, cebola, azeitonas e orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">04 - Baianinha</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">35,00</h4>
              <h4 className="item-price">50,00</h4>
              <h4 className="item-price">64,00</h4>
            </div>
          </div>

          <p className="item-desc">
            Molho, mussarela, calabresa, pimenta, azeitonas e orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">05 - Bauru</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">35,00</h4>
              <h4 className="item-price">50,00</h4>
              <h4 className="item-price">64,00</h4>
            </div>
          </div>

          <p className="item-desc">
            Molho, presunto, cebola, tomate, mussarela, azeitonas e orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">06 - Brasileira</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">43,00</h4>
              <h4 className="item-price">58,00</h4>
              <h4 className="item-price">72,00</h4>
            </div>
          </div>

          <p className="item-desc">
            Molho, mussarela, atum, milho, bacon, cebola, azeitonas e orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">07 - Brócolis</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">38,00</h4>
              <h4 className="item-price">53,00</h4>
              <h4 className="item-price">67,00</h4>
            </div>
          </div>

          <p className="item-desc">
            Molho, mussarela, brócolis, bacon, alho, catupiry, parmesão,
            azeitonas e orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">08 - Calabresa l</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">35,00</h4>
              <h4 className="item-price">50,00</h4>
              <h4 className="item-price">64,00</h4>
            </div>
          </div>

          <p className="item-desc">
            Molho, mussarela, calabresa, cebola, azeitonas e orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">09 - Calabresa Especial</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">38,00</h4>
              <h4 className="item-price">53,00</h4>
              <h4 className="item-price">67,00</h4>
            </div>
          </div>

          <p className="item-desc">
            Molho, mussarela, calabresa, tomate, bacon, catupiry, azeitonas e
            orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">11 - Contra Filé</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">45,00</h4>
              <h4 className="item-price">63,00</h4>
              <h4 className="item-price">77,00</h4>
            </div>
          </div>

          <p className="item-desc">
            Molho, mussarela, catupiry, contra filé ao molho madeira, cebola
            roxa, azeitonas e orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">12 - Crocante</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">41,00</h4>
              <h4 className="item-price">56,00</h4>
              <h4 className="item-price">70,00</h4>
            </div>
          </div>

          <p className="item-desc">
            Molho, mussarela, frango, milho, bacon, batata palha, cheddar,
            azeitonas e orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">13 - Frango c/ Catupiry</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">35,00</h4>
              <h4 className="item-price">50,00</h4>
              <h4 className="item-price">64,00</h4>
            </div>
          </div>

          <p className="item-desc">
            Molho, frango, catupiry, tomate, azeitonas e orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">14 - Frango Especial</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">38,00</h4>
              <h4 className="item-price">53,00</h4>
              <h4 className="item-price">67,00</h4>
            </div>
          </div>

          <p className="item-desc">
            Molho, frango, milho, mussarela, bacon, catupiry, azeitonas e
            orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">15 - Frango c/ Brócolis</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">41,00</h4>
              <h4 className="item-price">56,00</h4>
              <h4 className="item-price">70,00</h4>
            </div>
          </div>

          <p className="item-desc">
            Molho, mussarela, frango, brócolis, catupiry, parmesão, azeitonas e
            orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">16 - Frango c/ Cream Cheese</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">41,00</h4>
              <h4 className="item-price">56,00</h4>
              <h4 className="item-price">70,00</h4>
            </div>
          </div>

          <p className="item-desc">
            Molho, mussarela, frango, milho, cream cheese, bacon, azeitonas e
            orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">17 - Hot Dog</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">38,00</h4>
              <h4 className="item-price">53,00</h4>
              <h4 className="item-price">67,00</h4>
            </div>
          </div>

          <p className="item-desc">
            Molho, mussarela, salsicha, bacon, milho, batata palha, catupiry,
            azeitonas e orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">18 - Lombo c/ Cream Cheese</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">41,00</h4>
              <h4 className="item-price">56,00</h4>
              <h4 className="item-price">70,00</h4>
            </div>
          </div>

          <p className="item-desc">
            Molho, mussarela, lombo, cream cheese, azeitonas e orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">19 - Lombo Especial</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">38,00</h4>
              <h4 className="item-price">53,00</h4>
              <h4 className="item-price">67,00</h4>
            </div>
          </div>

          <p className="item-desc">
            Molho, mussarela, lombo canadense, catupiry, bacon, tomate,
            azeitonas e orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">20 - Marguerita</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">38,00</h4>
              <h4 className="item-price">53,00</h4>
              <h4 className="item-price">67,00</h4>
            </div>
          </div>

          <p className="item-desc">
            Molho, mussarela, manjericão, parmesão, tomate, azeitonas e orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">21 - Mexicana</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">38,00</h4>
              <h4 className="item-price">53,00</h4>
              <h4 className="item-price">67,00</h4>
            </div>
          </div>

          <p className="item-desc">
            Molho, mussarela, calabresa, tomate, pimentão, bacon, alho frito,
            parmesão, azeitonas e orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">22 - Milho</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">35,00</h4>
              <h4 className="item-price">50,00</h4>
              <h4 className="item-price">64,00</h4>
            </div>
          </div>

          <p className="item-desc">
            Molho, mussarela, milho, catupiry, azeitonas e orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">23 - Moda da Casa</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">43,00</h4>
              <h4 className="item-price">58,00</h4>
              <h4 className="item-price">72,00</h4>
            </div>
          </div>

          <p className="item-desc">
            Molho, calabresa, milho, ervilhas, ovos, palmito, mussarela, bacon,
            azeitonas e orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">24 - Moda do Chefe</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">38,00</h4>
              <h4 className="item-price">53,00</h4>
              <h4 className="item-price">67,00</h4>
            </div>
          </div>

          <p className="item-desc">
            Molho, mussarela, calabresa, milho, ovos, tomate, cebola, azeitonas
            e orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">25 - Mussarela</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">38,00</h4>
              <h4 className="item-price">53,00</h4>
              <h4 className="item-price">67,00</h4>
            </div>
          </div>

          <p className="item-desc">
            Molho, mussarela, tomate, azeitonas e orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">26 - Palmito</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">41,00</h4>
              <h4 className="item-price">56,00</h4>
              <h4 className="item-price">70,00</h4>
            </div>
          </div>

          <p className="item-desc">
            Molho, mussarela, palmito, cebola, catupiry, parmesão, azeitonas e
            orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">27 - Peito de Peru c/ Cream Cheese</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">43,00</h4>
              <h4 className="item-price">58,00</h4>
              <h4 className="item-price">72,00</h4>
            </div>
          </div>

          <p className="item-desc">
            Molho, mussarela, peito de peru, cream cheese, azeitonas e orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">28 - Peito de Peru Especial</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">41,00</h4>
              <h4 className="item-price">56,00</h4>
              <h4 className="item-price">70,00</h4>
            </div>
          </div>

          <p className="item-desc">
            Molho, mussarela, peito de peru, tomate, cebola, catupiry, azeitonas
            e orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">29 - Pepperoni</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">45,00</h4>
              <h4 className="item-price">60,00</h4>
              <h4 className="item-price">74,00</h4>
            </div>
          </div>

          <p className="item-desc">
            Molho, mussarela, pepperoni, tomate, cebola, azeitonas e orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">30 - Pizza Burguer</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">48,00</h4>
              <h4 className="item-price">63,00</h4>
              <h4 className="item-price">77,00</h4>
            </div>
          </div>

          <p className="item-desc">
            Molho, mussarela, milho, tomate, hamburguer, catchup, maionese,
            batata palha, bacon, ovos, azeitonas e orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">31 - Portuguesa</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">38,00</h4>
              <h4 className="item-price">56,00</h4>
              <h4 className="item-price">70,00</h4>
            </div>
          </div>

          <p className="item-desc">
            Molho, presunto, palmito, ervilha, ovos, tomate, cebola, mussarela,
            azeitonas e orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">32 - Tomate Seco</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">38,00</h4>
              <h4 className="item-price">53,00</h4>
              <h4 className="item-price">67,00</h4>
            </div>
          </div>

          <p className="item-desc">
            Molho, mussarela, tomate seco, rúcula, azeitonas e orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">33 - Tradicional</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">35,00</h4>
              <h4 className="item-price">50,00</h4>
              <h4 className="item-price">64,00</h4>
            </div>
          </div>

          <p className="item-desc">
            Molho, mussarela, presunto, tomate, azeitonas e orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">34 - 4 Queijos</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">43,00</h4>
              <h4 className="item-price">58,00</h4>
              <h4 className="item-price">72,00</h4>
            </div>
          </div>

          <p className="item-desc">
            Molho, mussarela, provolone, catupiry, parmesão, azeitonas e
            orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">35 - 5 Queijos</h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">43,00</h4>
              <h4 className="item-price">58,00</h4>
              <h4 className="item-price">72,00</h4>
            </div>
          </div>

          <p className="item-desc">
            Molho, mussarela, provolone, catupiry, gorgonzola, parmesão,
            azeitonas e orégano.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">
              36 - Bacon supremo <span>Novidade</span>
            </h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">41,00</h4>
              <h4 className="item-price">56,00</h4>
              <h4 className="item-price">70,00</h4>
            </div>
          </div>

          <p className="item-desc">
            Molho, Mussarela, Bacon em tiras, cream cheese, tomate cereja e
            cebola roxa, orégano e azeitona.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">
              37 - Abobrinha <span>Novidade</span>
            </h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">38,00</h4>
              <h4 className="item-price">53,00</h4>
              <h4 className="item-price">67,00</h4>
            </div>
          </div>

          <p className="item-desc">
            Molho, Mussarela, abobrinha no azeite, tomate cereja, parmesão,
            orégano e azeitona.
          </p>
        </Item>

        <Item>
          <div className="item-separator">
            <h4 className="item-title">
              38 - Peito Supremo <span>Novidade</span>
            </h4>

            <div className="item-price-wrapper">
              <h4 className="item-price">45,00</h4>
              <h4 className="item-price">60,00</h4>
              <h4 className="item-price">74,00</h4>
            </div>
          </div>

          <p className="item-desc">
            Molho, Mussarela, Peito de Peru, cream cheese, tomate cereja,
            parmesão, orégano e azeito.
          </p>
        </Item>
      </Menu>
    </>
  );
};

export default IndexPage;
